import axios from 'axios'


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.response.use(response => response, async error => {
    const status = error?.response?.status

    if (status === 419) {
        // Refresh our session token
        await axios.get('/csrf-token')

        // Return a new request using the original request's configuration
        return axios(error.response.config)

    } else if (status === 401) {
        if (error.response.data?.loginUrl) {
            window.location.href = error.response.data.loginUrl


            if (window.doctorWorkplace) {
                const { useWorkPlaceStore } = (await import('@doctor-workplace/stores/workplace'))
                const workplace = useWorkPlaceStore()

                workplace.redirectingToEveselibaLogin = true
            }
        }
    }

    return Promise.reject(error)
})



export default class Http {
    static request(method, url, config = {}) {

        const promise = axios({
            method,
            url,
            [method === 'GET' ? 'params' : 'data']: config.data,
        })

        if ('success' in config || 'error' in config || 'always' in config) {
            return promise.then(response => {

                if (config.success instanceof Function) {
                    config.success(response.data)
                }

            }).catch(error => {
                console.error(error)

                // Jānorāda .catch(), lai nākamais .then() vienmēr izpildītos
                // https://github.com/axios/axios/issues/792
                if (config.error instanceof Function) {
                    config.error(error)
                }

            }).then(() => {
                if (config.always instanceof Function) {
                    config.always()
                }
            })

        } else {
            return promise.then(response => response.data)
        }
    }

    static post(url, config) {
        return this.request('POST', url, config)
    }

    static get(url, config) {
        return this.request('GET', url, config)
    }
}
